import React from "react"
import { Link } from "gatsby"

import { routes } from "@/utils/routes"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const MyInchmarlo: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2024/01/my-inchmarlo-website-banner.jpg"
          className="absolute inset-0 -z-[1]"
          alt="My Inchmarlo"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            <span className="text-primary">My Inchmarlo</span>
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="My Inchmarlo" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
          JOIN US ON <span className="text-primary">1 FEBRUARY 2024</span> FOR A TOUR OF YOUR OLD CLASSROOMS, DELVE INTO THE ARCHIVES AND ENJOY SUPPER WITH PEERS</h3>
          <table>
            <tr>
              <td><p>6:30pm&nbsp;&nbsp;&nbsp;</p></td>
              <td><p>Drinks Reception</p></td>
            </tr>
            <tr>
              <td><p>7pm&nbsp;&nbsp;&nbsp;</p></td>
              <td><p>Tour &amp; Archives</p></td>
            </tr>
            <tr>
              <td><p>8pm&nbsp;&nbsp;&nbsp;</p></td>
              <td><p>Supper</p></td>
            </tr>
            <tr>
              <td><p>9:15pm&nbsp;&nbsp;&nbsp;</p></td>
              <td><p>Wrap Up</p></td>
            </tr>
          </table>
          <p>
          After supper, some familiar faces will be sharing memories from different eras at Inchmarlo.
          </p>
          <p>
          Dress code: smart casual
          </p>
          <iframe
            id="etapIframe"
            style={{ border: "none", width: "100%", height: 1360 }}
            src="https://app.etapestry.com/onlineforms/TheRoyalBelfastAcademy/inchmarloreunion.html"
          ></iframe>
        </div>
      </div>
    </div>
  </Layout>
)

export default MyInchmarlo
